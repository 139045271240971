/*
* — Vendor —
*/
@import url("vendor/bootstrap.min.css");
@import url("vendor/aos.css");
@import url("vendor/slick.css");


/*
**	General
*/
body {
	font-family: 'Poppins', sans-serif;
	margin: 0 !important;
	padding: 0 !important;
}
p{
	color: #999999;
	line-height: 30px;
}
a{
	color: #999999;

	-webkit-transition: all .15s ease-out 0s;
    -moz-transition: all .15s ease-out 0s;
    -o-transition: all .15s ease-out 0s;
    transition: all .15s ease-out 0s;
}
footer a:hover{
	color: #B61B8A;
	text-decoration: none;
}

.small-margin{
	margin-top: 42px;
}
.medium-margin{
	margin-top: 80px;
}
.big-margin{
	margin-top: 200px;
}

button.btn{
	font-weight: 600;
}
button.btn-lg{
	font-size: 20px;
	padding: 0.8rem 1.8rem;
}
button.btn-md{
	font-size: 16px;
	padding: 0.6rem 1.4rem;
}
button.btn-sm{
	font-size: 12px;
}
button.btn-sd-primary{
	color: #fff;
    background-color: #B61B8A;
    border-color: #B61B8A;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(153,8,112,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(153,8,112,1);
	box-shadow: 0px 3px 0px 0px rgba(153,8,112,1);
}
button.btn-sd-primary:hover{
	color: #fff;
    background-color: #990870;
    border-color: #990870;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(112,6,82,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(112,6,82,1);
	box-shadow: 0px 3px 0px 0px rgba(112,6,82,1);
}
button.btn-sd-primary:focus{
	color: #fff;
	outline: 0;
	background-color: #CC33A1;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(112,6,82,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(112,6,82,1);
	box-shadow: 0px 3px 0px 0px rgba(112,6,82,1);
}
button.btn-sd-secondary{
	color: #B61B8A;
    background-color: #FFFFFF;
    border: 2px solid #B61B8A;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(222,222,222,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(222,222,222,1);
	box-shadow: 0px 3px 0px 0px rgba(222,222,222,1);
}
button.btn-sd-secondary:hover{
	color: #B61B8A;
    background-color: #F2F2F2;
    border-color: #990870;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
	box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
}
button.btn-sd-secondary:focus{
	color: #B61B8A;
	outline: 0;
	background-color: #F2F2F2;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
	box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
}

section .section-title h2{
	font-size: 42px;
	font-weight: 900;
	line-height: 52px;
	color: #303133;
}
section .section-title h2::after{
	content: '';
    width: 25%;
    height: 3px;
    display: block;
    background: #FF8F00;
    margin: 0 auto;
    margin-top: 28px;
}
section .section-title.bar-left h2::after{
	margin: initial;
	margin-top: 28px;
}
section .section-title.inverted-title h2{
	color: #FFFFFF;
}
section .section-title.inverted-title h2::after{
    background: #B61B8A;
}
section p.section-text {
	font-size: 18px;
	line-height: 32px;
}

form label{
	font-weight: 600;
}

.wave{
	width: 100%;
	height: 418px;
	background: url('../images/wave.svg');
	margin-top: -368px;
}

.box{
	height: 100%;
	background: #FFFFFF;
	box-shadow: 0 2px 20px rgba(34, 31, 31, 0.12);
	padding:50px 30px;
}
.box .box-body img.box-icon{
	width: auto;
	height: 100px;
}
.box .box-body h5.box-title, 
.box-transparent .box-body h5.box-title{
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
}
.box .box-body p.box-text,
.box-transparent .box-body p.box-text{
	font-size: 16px;
}
.box .box-body .box-extra h5{
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0px;
}
.box .box-body .box-extra small{
	font-size: 13px;
	color: #999999;
}
.box-transparent{
	color: #FFFFFF;
	padding:50px 30px;
}
.box-transparent .box-body p.box-text{
	color: #FFFFFF !important;
}


/*
**	Above the fold
*/

section.above-the-fold{
	width: 100%;
	max-height: 1020px;
	background: url('../images/main_bg.jpg');
	background-size: cover;
    padding: 1rem 2rem 4rem 2rem;
}
section.above-the-fold h1.display-1{
	font-size:50px;
	font-weight: 900;
	color: #FFFFFF;
}
section.above-the-fold p{
	font-size: 20px;
	line-height: 34px;
	color: #FFFFFF;
}


/*
**	App features
*/

section.app-features .smartphone-mockup{
	position: relative;
}
section.app-features .smartphone-mockup .mockup-overlay{
	position: absolute;
	top: 87px;
	left: -2px;
}
section.app-features .smartphone-mockup .mockup-overlay .carousel-item{
	width: auto;
}
section.app-features .smartphone-mockup .mockup-overlay img{
	width: 84%;
}
section.app-features ul.list-unstyled li.media .media-body h5{
	font-size: 24px;
	font-weight: 600;
}
section.app-features ul.list-unstyled li.media .media-icon{
	width: 60px;
}


/*
**	Dashboard features
*/

section.dashboard-features .movie-container::after{
	content: "";
    position: absolute;
    bottom: 6px;
    top: 30px;
    left: 30px;
    right: 30px;
    z-index: -1;
    border-radius: 100px/30px;
	-webkit-box-shadow: 0 23px 40px -6px #222;
	-moz-box-shadow: 0 23px 40px -6px #222;
	box-shadow: 0 23px 40px -6px #222;
}


/*
**	Company benefits
*/

section.company-benefits{
	background: #303133;
	padding-top: 384px;
	padding-bottom: 120px;
	margin-top: -260px;
}
section.company-benefits h2{
	color: #FFFFFF;
}


/*
**	Testimonial
*/

section.testimonial{
	background: #FAFBFF;
	padding-top: 200px;
	padding-bottom: 200px;
}
section.testimonial .box{
	margin: 20px;
}
section.testimonial .slick-slide img{
    display: initial !important;
}


/*
**	Questions
*/

section.questions .card{
	border: 0px;
}
section.questions .card:last-child .card-header{
	border-bottom: 0px;
}
section.questions .card .card-header{
	background-color: #FFF;
	padding: 28px 24px;
	cursor: pointer;
	border-radius: 0;
	border-bottom: 1px solid #F2F2F2;
	position: relative;
}
section.questions .card .card-header h5{
	font-size: 16px;
	font-weight: 600;
	color: #303133;
}
section.questions .card .card-header .arrow{
	position: absolute;
	right: 16px;
	top: 50%;
	margin-top: -4px;

    -webkit-transition: all .15s ease-out 0s;
    -moz-transition: all .15s ease-out 0s;
    -o-transition: all .15s ease-out 0s;
    transition: all .15s ease-out 0s;
}
section.questions .card .card-header.collapsed .arrow{
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}


/*
**	Highlight
*/

section.highlight{
	background-color: #FF8F00;
	padding-top: 100px;
	padding-bottom: 100px;
}
section.highlight h2.display-1{
	font-size: 50px;
	font-weight: 900;
	color: #FFF;
}
section.highlight button.btn{
	margin-top: 62px;
}


/*
**	Footer
*/

footer{
	padding-top: 60px;
}
footer p, footer a{
	font-size: 13px;
	line-height: 24px;
}
footer h6{
	font-size: 18px;
	font-weight: 600;
	color: #303133;
}
footer .btn-sd-primary{
	font-size: 14px;
}
footer .social-media a{
	margin-right: 15px;
}
footer .social-media a:hover{
	text-decoration: none;
}
footer button.btn-with-icon .btn-icon{
	margin-right: 8px;
}
footer button.btn-with-icon span{
	display: block;
	text-align: left;
}
footer button.btn-with-icon span.small-text{
	font-size: 10px;
}
footer button.btn-with-icon span.big-text{
	font-size: 16px;
	font-weight: 600;
	margin-top: -6px;
}
footer .line{
	width: 100%;
	border-bottom: 1px solid #F2F2F2;
}
footer p.rights{
	margin-top: 10px;
	margin-bottom: 0px;
}


/*
**	Others
*/
.aos-fix{
	overflow-x: hidden !important;
}
.slick-arrow{
    width: 56px;
    height: 56px;
    font-size: 30px;
    color: #B61B8A;
	background: transparent;
    border: 3px solid #B61B8A;
    border-radius: 30px;
    padding-top: 2px;
    margin: 20px 10px;
    cursor: pointer;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(222,222,222,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(222,222,222,1);
	box-shadow: 0px 3px 0px 0px rgba(222,222,222,1);
}
.slick-arrow:hover{
	background-color: #F2F2F2;
    border-color: #990870;

    -webkit-box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
	-moz-box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
	box-shadow: 0px 3px 0px 0px rgba(187,187,187,1);
}
.modal .modal-header h5{
	font-weight: 900;
}
.movie-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    max-width: 100%;
}

.movie-container iframe,
.movie-container object,
.movie-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.bg-shadow.left{
	position: absolute;
	top: 200%;
	left: 0px;
	z-index: -1;
}
.bg-shadow.right{
	position: absolute;
	right: 0px;
	z-index: -1;
}


/* Dispositivos small (telefones em modo paisagem, com 576px ou mais) */
@media (max-width: 576px) {
	.small-margin{
		margin-top: 32px;
	}
	.big-margin{
		margin-top: 120px;
	}
	.mobile-margin-items *{
		margin-bottom: 24px;
	}
	.mobile-margin-top{
		margin-top: 60px;
	}
	.box{
		height: auto;
	}
	.box-transparent{
	    padding: 16px 12px;
	}
	.logo{
		margin-top: 16px;
	}
	.bg-shadow{
		display: none;
	}
	section.above-the-fold{
		padding: 1rem .3rem 6rem .3rem;
		text-align: center;
	}
	section.above-the-fold h1.display-1{
		font-size: 32px;
	}
	section .section-title h2{
		font-size: 32px;
		line-height: 1.3;
	}
	section.app-features .smartphone-mockup{
		text-align: center;
	}
	section.app-features .smartphone-mockup .mockup-overlay{
		left: -2px !important;
	}
	section.app-features .smartphone-mockup .mockup-overlay .carousel-item{
		width: 100%;
	}
	section.app-features .smartphone-mockup .mockup-overlay img {
		width: 95% !important;
	}
	section .dashboard-features .movie-container iframe{
		width: 100% !important;
	}
	section.company-benefits{
		margin-top: -100px;
	    padding-top: 120px;
	}
	section.testimonial{
		padding-top: 120px;
	    padding-bottom: 120px;
	}
	section.testimonial .box {
		margin: 12px;
	}
	section.highlight{
		text-align: center;
	}
	section.highlight h2.display-1{
		font-size: 42px;
	}
	footer .footer-item{
		margin-top: 32px;
	}
	footer .footer-info{
		text-align: center;
	}
	footer button.btn-with-icon{
		padding: 0.5rem 0.8rem;
	}
	footer .footer-credits{
		text-align: center;
		margin-bottom: 26px;
	}
	footer .footer-credits img{
		margin-top: 16px;
	}
}

/* Dispositivos médios (tablets com 768px ou mais) */
@media (max-width: 768px) {
	.container {
	    max-width: 640px;
	}
	.big-margin{
		margin-top: 120px;
	}
	.mobile-margin-items *{
		margin-bottom: 24px;
	}
	.mobile-margin-top{
		margin-top: 60px;
	}
	.bg-shadow{
		display: none;
	}
	section.above-the-fold{
		padding: 1rem .3rem 8rem .3rem;
	}
	section.above-the-fold .phone-mockup{
		display: none;
	}
	section.app-features .smartphone-mockup{
		text-align: center;
	}
	section.app-features .smartphone-mockup .mockup-overlay{
		left: 94px;
	}
	section.app-features .smartphone-mockup .mockup-overlay img {
		width: 84%;
	}
	footer .btn{
		max-width: 100%;
	}
	footer .footer-item{
		margin-top: 32px;
	}
	footer button.btn-with-icon{
		padding: 0.5rem 0.8rem;
	}
	footer .footer-credits{
		text-align: center;
		margin-bottom: 26px;
	}
	footer .footer-credits img{
		margin-top: 16px;
	}
}

/* Dispositivos large (desktops com 992px ou mais) */
@media (max-width: 992px) {
	.bg-shadow{
		display: none;
	}
}

/* Dispositivos extra large (desktops grandes com 1200px ou mais) */
@media (min-width: 1200px) {

}